import React from "react";

import Picture from "../Picture";

interface Props {
  page: Showroom;
  locale: string;
  setShownItem: (item: Showroom) => void;
}

export default function Item(props: Props) {
  const { name, excerpt, url, type, thumbnail, video_embed } = props.page;

  const handleClick = (evt: React.MouseEvent) => {
    if (video_embed) {
      evt.preventDefault();
      props.setShownItem(props.page);
    }
  };

  // const readMoreLabel = () => {
  //   return props.locale == "nb" ? "Les mer" : "Read more";
  // };

  return (
    <article>
      <a href={url} onClick={handleClick} className="image">
        {thumbnail && (
          <Picture image={thumbnail} sizes="(max-width: 900px) 100vw, 50vw" />
        )}
      </a>
      <h2>
        <a href={url} onClick={handleClick}>
          {name}
        </a>
      </h2>
      {type && <div className="showroom-type">{type}</div>}
      {excerpt && (
        <div
          className="excerpt"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      )}
    </article>
  );
}
