import React from "react";

import Picture from "../Picture";

interface Props {
  image: GalleryImage;
  url: string;
  showImage: (item: GalleryImage) => void;
}

export default function Image(props: Props) {
  const { image } = props;

  const handleClick = (evt: React.MouseEvent) => {
    if (!evt.ctrlKey && !evt.metaKey) {
      evt.preventDefault();
      props.showImage(image);
    }
  };

  return (
    <a href={props.url} className="article" onClick={handleClick}>
      <div className="image">
        <Picture
          image={image.thumbnail}
          sizes="(max-width: 900px) 50vw, 25vw"
        />
      </div>
      {image.caption && <figcaption>{image.caption}</figcaption>}
    </a>
  );
}
