import React from "react";

interface Props {
  image: Picture;
  onLoad?: (evt: React.SyntheticEvent) => void;
  sizes?: string;
}

export default function Picture(props: Props) {
  const { image } = props;

  const sizes = props.sizes || "100vw";

  const handleLoaded = (evt: React.SyntheticEvent) => {
    if (props.onLoad) {
      props.onLoad(evt);
    }
  };

  return (
    <picture>
      {image.webp_srcset && (
        <source type="image/webp" srcSet={image.webp_srcset} sizes={sizes} />
      )}
      <img
        onLoad={handleLoaded}
        src={image.src}
        alt={image.alternative}
        srcSet={image.srcset}
        width={image.width}
        height={image.height}
        sizes={sizes}
      />
    </picture>
  );
}
