import React, { ChangeEvent } from "react";

interface Props {
  name: string;
  options: string[][];
  value: string;
  onChange: (value: string) => void;
}

export default function FilterSelect(props: Props) {
  function handleChange(evt: ChangeEvent<HTMLSelectElement>) {
    props.onChange(evt.target.value);
  }

  return (
    <div className="filter-select">
      <select name={props.name} value={props.value} onChange={handleChange}>
        {props.options.map((o, i) => (
          <option key={i} value={o[1]}>
            {o[0]}
          </option>
        ))}
      </select>
    </div>
  );
}
