import React from "react";

interface Props {
  event: CalendarEvent;
  showPage: boolean;
}

function optionalLink(obj: CalendarEvent | Page) {
  if (obj.url) {
    return <a href={obj.url}>{obj.name}</a>;
  } else {
    return obj.name;
  }
}

export default function CalendarRow(props: Props) {
  const event = props.event;

  return (
    <div className={"calendar-event " + (props.showPage ? "with-page" : "")}>
      <span className="date">{event.formattedDate}</span>
      {props.showPage && (
        <span className="project">
          {event.page && optionalLink(event.page)}
        </span>
      )}
      <span className="name">{optionalLink(event)}</span>
      <span className="type-name">{event.typeName}</span>
    </div>
  );
}
