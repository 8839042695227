import React, { useState } from "react";
import Calendar from "./Calendar";
import FilterSelect from "./FilterSelect";

interface Props {
  events: CalendarEvent[];
  showPage: boolean;
  strings: Record<string, string>;
}

function extractPages(events: CalendarEvent[]) {
  const pages = events.map((e) => e.page).filter((p) => p);
  const pageMap: Record<string, Page> = {};
  pages.forEach((p) => (pageMap[p.id] = p));

  return uniq(pages.map((p: Page) => p.id))
    .map((id) => pageMap[id])
    .sort((a: Page, b: Page) => {
      return a.name
        .toUpperCase()
        .localeCompare(b.name.toUpperCase(), "nb", { sensitivity: "base" });
    });
}

function extractYears(events: CalendarEvent[]) {
  return uniq(events.map((e) => new Date(e.date).getFullYear().toString()))
    .sort()
    .reverse();
}

function filterEvents(
  allEvents: CalendarEvent[],
  year: string,
  pageId: string,
  eventType: string
) {
  let events = allEvents.filter((e) => {
    const date = new Date(e.date);
    if (year === "upcoming") {
      return date >= new Date();
    } else {
      return date.getFullYear().toString() === year;
    }
  });

  if (pageId !== "all") {
    events = events.filter((e) => e.page && e.page.id == pageId);
  }

  if (eventType !== "all") {
    events = events.filter((e) => e.typeName === eventType);
  }

  if (year === "upcoming") {
    events = events.reverse();
  }

  return events;
}

function uniq<T>(array: Array<T>): Array<T> {
  return array.filter((v, index, self) => self.indexOf(v) === index);
}

function eventTypeOptions(props: Props) {
  return [[props.strings.all_event_types, "all"]].concat(
    uniq(props.events.map((e) => e.typeName))
      .sort()
      .map((n) => [n, n])
  );
}

function pageIdOptions(props: Props) {
  return [[props.strings.all_projects, "all"]].concat(
    extractPages(props.events).map((p: Page) => [p.name, p.id])
  );
}

function yearOptions(props: Props) {
  const today = new Date();
  const yearOpts = extractYears(props.events).map((y) => [y, y]);

  if (props.events.filter((e) => new Date(e.date) >= today).length > 0) {
    return [[props.strings.upcoming, "upcoming"]].concat(yearOpts);
  } else {
    return yearOpts;
  }
}

export default function FilterableCalendar(props: Props) {
  const yearOpts = yearOptions(props);

  const [year, setYear] = useState(yearOpts[0][1]);
  const [pageId, setPageId] = useState("all");
  const [eventType, setEventType] = useState("all");

  return (
    <div className="calendar">
      <div className="filter">
        <FilterSelect
          name="year"
          value={year}
          options={yearOpts}
          onChange={(y) => setYear(y)}
        />
        {props.showPage && (
          <FilterSelect
            name="pageId"
            value={pageId}
            options={pageIdOptions(props)}
            onChange={(id) => setPageId(id)}
          />
        )}
        <FilterSelect
          name="eventType"
          value={eventType}
          options={eventTypeOptions(props)}
          onChange={(t) => setEventType(t)}
        />
      </div>
      <Calendar
        events={filterEvents(props.events, year, pageId, eventType)}
        showPage={props.showPage}
      />
    </div>
  );
}
