import React, { ReactNode, useEffect, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
  children: ReactNode;
  onClose: () => void;
  locale: string;
}

function closeLabel(locale: string) {
  if (locale == "nb") {
    return "Lukk";
  } else {
    return "Close";
  }
}

export default function Modal(props: Props) {
  const el = document.createElement("div");
  el.classList.add("modal-wrapper");

  const close = (evt: React.MouseEvent | KeyboardEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleKeypress = (evt: KeyboardEvent) => {
    if (evt.which == 27) {
      close(evt);
    }
  };

  useLayoutEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  });

  return createPortal(
    <>
      <div className="modal-background" onClick={close} />
      <div className="modal-content">
        <header>
          <div className="inner">
            <button className="close-modal" onClick={close}>
              {closeLabel(props.locale)}
            </button>
          </div>
        </header>
        <main>
          <div className="inner">{props.children}</div>
        </main>
      </div>
    </>,
    el
  );
}
