import React, { useState } from "react";

import Item from "./ShowroomGroup/Item";
import Modal from "./Modal";

interface Props {
  pages: Showroom[];
  locale: string;
}

export default function ShowroomGroup(props: Props) {
  const pages = props.pages;

  const [shownItem, setShownItem] = useState<Showroom | null>(null);

  const closeModal = (evt?: React.MouseEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setShownItem(null);
  };

  return (
    <>
      {shownItem && (
        <Modal onClose={closeModal} locale={props.locale}>
          <div
            className="video-embed"
            dangerouslySetInnerHTML={{ __html: shownItem.video_embed }}
          />
        </Modal>
      )}
      <div className="showroom-group grid-2">
        {pages.map((p) => (
          <Item
            key={p.id}
            page={p}
            locale={props.locale}
            setShownItem={setShownItem}
          />
        ))}
      </div>
    </>
  );
}
