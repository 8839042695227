import React from "react";

import useLightbox from "../hooks/useLightbox";
import Lightbox from "./Lightbox";
import Modal from "./Modal";
import Image from "./ImageGallery/Image";

interface Props {
  images: GalleryImage[];
  locale: string;
}

export default function ImageGallery(props: Props) {
  const images = props.images;

  const imageUrl = (image: GalleryImage) => {
    return `#images/${image.id}`;
  };

  const [shownImage, setShownImage, navigate] = useLightbox(images, imageUrl);

  const closeModal = (evt?: React.MouseEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setShownImage(null);
  };

  return (
    <>
      {shownImage && (
        <Modal onClose={closeModal} locale={props.locale}>
          <Lightbox image={shownImage.image} navigate={navigate}>
            {shownImage.caption && (
              <div>
                <figcaption>{shownImage.caption}</figcaption>
              </div>
            )}
          </Lightbox>
        </Modal>
      )}
      <div className="image-gallery grid-4">
        {images.map((i) => (
          <Image
            key={i.id}
            image={i}
            showImage={setShownImage}
            url={imageUrl(i)}
          />
        ))}
      </div>
    </>
  );
}
