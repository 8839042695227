import React from "react";
import CalendarRow from "./CalendarRow";

interface Props {
  events: CalendarEvent[];
  showPage: boolean;
}

export default function Calendar(props: Props) {
  return (
    <div className="calendar-list">
      {props.events.map((e) => (
        <CalendarRow key={e.id} showPage={props.showPage} event={e} />
      ))}
    </div>
  );
}
